'use client';
import {
  type KeyboardEvent,
  type MouseEvent,
  useCallback,
  useState,
  useTransition,
} from 'react';
import { gtmTrack } from '@mentimeter/google-tracking';
import { ChevronDownIcon, GlobeIcon } from '@mentimeter/ragnar-visuals';
import { useRouter } from '@mentimeter/next-navigation';
import { getPathnameWithoutLocale } from '@mentimeter/i18n';
import { LOCALES, DEFAULT_LOCALE } from 'src/constants.cjs';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Link } from '@mentimeter/ragnar-ui/link';
import { Collapsable } from '@mentimeter/ragnar-ui/collapsable';
import { usePathname } from '../i18n/navigation';
import { setUserLocale } from '../utils/set-user-locale';
import { Wide } from '../ui/layout';

interface LanguagesT {
  [key: string]: string;
}
const LANGUAGES: LanguagesT = {
  'en-US': 'English',
  'de-DE': 'German',
  'pt-BR': 'Portuguese (Brazil)',
  'es-ES': 'Spanish',
};

export function FooterSwitchLanguage() {
  const [showMenu, setShowMenu] = useState(false);
  const pathname = usePathname();
  const pathnameWithoutLocale = getPathnameWithoutLocale(pathname, LOCALES);
  const router = useRouter();
  const [_, startTransition] = useTransition();

  const switchLanguage = useCallback(
    (nextLocale: string) => {
      setUserLocale(nextLocale);

      gtmTrack({
        event: 'choseLanguage',
        type: LANGUAGES[nextLocale],
      });

      startTransition(() => {
        if (nextLocale === DEFAULT_LOCALE) {
          router.push(pathnameWithoutLocale);
        } else {
          router.push(`/${nextLocale}${pathnameWithoutLocale}`);
        }
      });
    },
    [router, startTransition, pathnameWithoutLocale],
  );

  const handleClick = useCallback(
    (nextLocale: string) => (event: MouseEvent) => {
      event.stopPropagation();
      switchLanguage(nextLocale);
    },
    [switchLanguage],
  );

  const handleKeyPress = useCallback(
    (nextLocale: string) => (e: KeyboardEvent) => {
      e.stopPropagation();
      if (e.key === 'Enter') {
        switchLanguage(nextLocale);
      }
    },
    [switchLanguage],
  );

  return (
    <Wide
      flex="1 1 auto"
      alignItems={['stretch', 'center']}
      flexDirection="column"
      mt="space4"
      mb="space8"
    >
      <Box alignItems={['stretch', 'center']}>
        <Box
          my="space1"
          alignItems="stretch"
          borderColor="text"
          borderBottomWidth="1px"
          borderStyle="solid"
          mb={showMenu ? 'space4' : 'space0'}
          extend={() => ({
            '@media screen and (min-width: 961px)': {
              borderBottomWidth: '0px',
            },
          })}
        >
          <Clickable
            onClick={() => setShowMenu(!showMenu)}
            flexDirection="row"
            justifyContent="space-between"
            aria-expanded={showMenu}
          >
            <Box flexDirection="row">
              <GlobeIcon size={3} />
              <Text fontWeight="semiBold" ml="space2">
                Choose your language
              </Text>
            </Box>
            <Box
              mt="space1"
              extend={() => ({
                transition: 'transform 0.2s ease',
                transform: showMenu ? 'rotate(-180deg)' : 'rotate(0)',
              })}
            >
              <ChevronDownIcon color="text" size={2} />
            </Box>
          </Clickable>
        </Box>
        <Collapsable show={showMenu} width="100%">
          {LOCALES.map((locale: string) => (
            <Box mb="space2" key={locale}>
              <Link
                fontSize="100"
                color="textWeak"
                tabIndex={showMenu ? 0 : -1} // If menu is hidden, links should not be focusable
                underline={false}
                // there's a better way, but it's incompatible currently with our setup
                // read more: https://next-intl-docs.vercel.app/docs/routing/navigation#link
                href={
                  locale === DEFAULT_LOCALE
                    ? pathnameWithoutLocale
                    : `/${locale}${pathnameWithoutLocale}`
                }
                onClick={handleClick(locale)}
                onKeyUp={handleKeyPress(locale)}
              >
                {LANGUAGES[locale]}
              </Link>
            </Box>
          ))}
        </Collapsable>
      </Box>
    </Wide>
  );
}
