import * as React from 'react';
import { LOCALES } from 'src/constants.cjs';
import { getPathnameWithoutLocale, useTranslations } from '@mentimeter/i18n';
import { usePathname } from '@mentimeter/next-navigation';
import { Box } from '@mentimeter/ragnar-ui/box';
import { MenuDropdown } from './MenuDropdown';
import { NavLink } from './NavLink';
import type { MenuAction } from '.';

interface PropsT {
  actions: MenuAction[];
  overviewLabel?: string | undefined;
}

export const ActionMenu = ({ actions, overviewLabel }: PropsT) => {
  const pathname = usePathname();
  const pathNameWithoutLocale = getPathnameWithoutLocale(pathname, LOCALES);
  const [selectedMenu, setSelectedMenu] = React.useState<string | null>(null);
  const t = useTranslations('common');

  return (
    <>
      {actions.map((action) => (
        <Box as="li" mx="space2" key={action.name}>
          {action.subActions ? (
            <MenuDropdown
              action={action}
              overviewLabel={overviewLabel}
              setSelectedMenu={setSelectedMenu}
              show={selectedMenu === action.referenceId}
              openDropdownLabel={`${t('page_links.dropdown')} - ${action.name}`}
            />
          ) : (
            <NavLink
              href={action.href}
              active={action.href === pathNameWithoutLocale}
              onClick={() => action.onClick?.()}
              prefetch={Boolean(action.prefetch)}
              mr="space1"
            >
              {action.name}
            </NavLink>
          )}
        </Box>
      ))}
    </>
  );
};
