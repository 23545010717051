import * as React from 'react';
import type { LinkT } from '@mentimeter/ragnar-ui/link';
import { Link } from '../actions';

export type NavLinkT = LinkT & {
  active?: boolean;
  prefetch?: boolean | null;
};

export const NavLink = ({ active = false, ...linkProps }: NavLinkT) => {
  return (
    <Link
      fontWeight="semiBold"
      underline={false}
      fontSize="100"
      {...linkProps}
      color={active ? 'brand' : 'text'}
      extend={({ theme }) => ({
        opacity: 1,
        whiteSpace: 'nowrap',
        ':hover': {
          color: theme.colors.primary,
          textDecoration: 'none',
          opacity: 1,
        },
      })}
    />
  );
};
