/* eslint-disable menti-react/filename-convention--jsx */
'use client';
import * as React from 'react';
import { useLocale, useTranslations } from '@mentimeter/i18n';
import {
  DrawerRoot,
  DrawerTrigger,
  DrawerContainer,
  DrawerTitle,
  DrawerDismiss,
} from '@mentimeter/ragnar-ui/drawer';
import { IconButton } from '@mentimeter/ragnar-ui/icon-button';
import { ScreenReaderOnly } from '@mentimeter/ragnar-ui/screenreader-only';
import { Clickable as RagnarClickable } from '@mentimeter/ragnar-ui/clickable';
import { Box } from '@mentimeter/ragnar-ui/box';
import type { ButtonT } from '@mentimeter/ragnar-ui/button';
import type { LinkT } from '@mentimeter/ragnar-ui/link';
import {
  CrossIcon,
  MentimeterSymbol,
  MentimeterWordmark,
  MenuIcon,
} from '@mentimeter/ragnar-visuals';
import { getMentiAcademyURL } from '@mentimeter/user';
import { usePathname } from '@mentimeter/next-navigation';
import type { DisplayProperty } from '@mentimeter/ragnar-styled';
import { VotingBar } from '@mentimeter/voting-bar';
import { DEFAULT_LOCALE } from 'src/constants.cjs';
import { useReferralPropMapper } from 'src/utils/useReferralPropMapper';
import { useAppState } from 'src/appState';
import { Button, Clickable } from '../actions';
import { Wide } from '../layout';
import type { Action } from './ActionCollapsable';
import ActionCollapsable from './ActionCollapsable';
import { ActionMenu } from './ActionMenu';
import { NavLink } from './NavLink';

interface MenuSingleAction extends Action {
  referenceId?: string;
  subActions?: Action[];
}

interface MenuMultiAction extends MenuSingleAction {
  subActions?: MenuSingleAction[];
}

export type MenuAction = MenuSingleAction | MenuMultiAction;

export const isMenuMultiAction = (
  action: MenuAction,
): action is MenuMultiAction => {
  return (
    Boolean(action) &&
    'subActions' in action &&
    action.subActions.length > 0 &&
    'subActions' in action.subActions[0]!
  );
};

const useActions = (): MenuAction[] => {
  const t = useTranslations('common');
  const locale = useLocale();
  const enLocale = DEFAULT_LOCALE === locale;

  return [
    {
      name: t('page_links.business'),
      href: '/work',
      referenceId: 'work',
      subActions: [
        {
          name: t('page_links.employee_training'),
          href: '/work/training-and-onboarding',
        },
        {
          name: t('page_links.team_meetings'),
          href: '/work/team-meetings',
        },
        {
          name: t('page_links.workshops'),
          href: '/work/workshops',
        },
        {
          name: t('page_links.all_hands_meetings'),
          href: '/work/all-hands-meetings',
        },
        {
          name: t('page_links.brainstorming'),
          href: '/work/brainstorming',
        },
      ],
    },
    {
      name: t('page_links.education'),
      href: '/education',
      referenceId: 'education',
      subActions: [
        {
          name: t('page_links.lectures'),
          href: '/education/lectures',
        },
        {
          name: t('page_links.assessments'),
          href: '/education/assessments',
        },
        {
          name: t('page_links.seminars'),
          href: '/education/seminars',
        },
        {
          name: t('page_links.classroom_lessons'),
          href: '/education/classroom-lessons',
        },
        {
          name: t('page_links.hybrid_learning'),
          href: '/education/hybrid-learning',
        },
      ],
    },
    {
      name: t('page_links.enterprise'),
      hide: !enLocale,
      referenceId: 'enterprise-grade',
      subActions: [
        {
          name: t('page_links.enterprise'),
          href: '/enterprise',
        },
        {
          name: t('page_links.education'),
          href: '/education/enterprise',
        },

        {
          name: t('page_links.customer_stories'),
          href: '/stories',
        },
        {
          name: t('page_links.trust_center'),
          href: '/trust',
        },
      ],
    },
    {
      name: t('page_links.learn'),
      referenceId: 'learn',
      subActions: [
        {
          name: t('page_links.features_overview'),
          subActions: [
            {
              name: t('page_links.ai_builder'),
              href: '/features/ai-builder',
            },
            {
              name: t('page_links.live_polling'),
              href: '/features/live-polling',
            },
            {
              name: t('page_links.word_cloud'),
              href: '/features/word-cloud',
            },
            {
              name: t('page_links.quiz'),
              href: '/features/quiz-presentations',
            },
            {
              name: t('page_links.surveys'),
              href: '/features/surveys',
            },
            {
              name: t('page_links.presentation_maker'),
              href: '/features/presentation-maker',
            },
            {
              name: t('page_links.q&a'),
              href: '/features/live-questions-and-answers',
            },
          ],
        },
        {
          name: t('page_links.learning_hub'),
          subActions: [
            {
              name: t('page_links.how_to'),
              href: '/how-to',
            },
            {
              name: t('page_links.menti_academy'),
              href: getMentiAcademyURL(
                '?utm_source=mentimeter.com&utm_medium=referral&utm_campaign=top+navigation',
              ),
              target: '_blank',
              prefetch: false,
              rel: 'noopener noreferrer',
            },
            {
              name: t('page_links.templates'),
              href: '/templates',
            },
            {
              name: t('page_links.webinars'),
              href: '/webinars',
            },
            {
              name: t('page_links.blog'),
              href: '/blog',
            },
            {
              name: t('page_links.integrations'),
              href: '/integrations',
            },
            {
              name: t('page_links.help_center'),
              href: 'https://help.mentimeter.com',
              prefetch: false,
            },
          ],
        },
      ],
    },
    { name: t('page_links.pricing'), href: '/plans' },
    {
      name: t('page_links.get_enterprise'),
      href: '/enterprise',
    },
  ].filter(({ hide }) => !hide);
};

const LoadingButton = (buttonProps: ButtonT) => (
  <Button
    {...buttonProps}
    variant="brand"
    data-testid="header-button-loading"
    id="header-button-loading"
    state="loading"
    width={120}
  >
    Loading...
  </Button>
);

const LoginButton = (linkProps: LinkT) => {
  const t = useTranslations('common');

  return (
    <NavLink
      aria-label={t('page_links.login_aria')}
      href="/auth/login"
      data-testid="page-header-login"
      active={false}
      {...linkProps}
    >
      {t('page_links.login')}
    </NavLink>
  );
};

const SignupButton = (buttonProps: ButtonT) => {
  const referralPropMapper = useReferralPropMapper();
  const t = useTranslations('common');
  return (
    <Button
      {...buttonProps}
      variant="brand"
      data-testid="header-signup"
      {...referralPropMapper({ href: '/auth/signup' })}
    >
      {t('page_links.sign_up')}
    </Button>
  );
};

const HomeButton = (buttonProps: ButtonT) => {
  const t = useTranslations('common');

  return (
    <Button
      {...buttonProps}
      href="/app"
      variant="brand"
      data-testid="header-home"
      id="header-home"
    >
      {t('page_links.home')}
    </Button>
  );
};

export const HeaderButton = (buttonProps: ButtonT) => {
  const { user, userFinishedLoading } = useAppState();

  if (!userFinishedLoading) {
    return <LoadingButton {...buttonProps} />;
  }

  if (user) {
    return <HomeButton {...buttonProps} />;
  }

  return <SignupButton {...buttonProps} />;
};

interface Props {
  showVotingBar?: boolean;
}

export function Header({ showVotingBar = false }: Props) {
  const [showMenu, setShowMenu] = React.useState<boolean>(false);
  const pathname = usePathname();
  const actions = useActions();
  const t = useTranslations('common');
  const { userFinishedLoading, user } = useAppState();

  const visitor = userFinishedLoading && user === null;

  React.useEffect(() => {
    setShowMenu(false);
  }, [pathname]);

  const displayOnDesktop = ['none', 'none', 'flex'];
  const displayOnMobile = ['flex', 'flex', 'none'] as DisplayProperty[];

  return (
    <Box
      as="header"
      top="space0"
      zIndex={10}
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Box as="nav" width="100%">
        <Box
          px="space4"
          py={['space4', null, 'space8']}
          width="100%"
          alignItems="center"
          borderBottomWidth="1px"
          borderStyle="solid"
          borderColor="borderWeak"
          bg="bg"
          justifyContent="center"
          height={4}
        >
          <Wide
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            zIndex={10}
          >
            <Box flex="1">
              <Clickable
                href="/"
                data-testid="page-header-logo"
                aria-label={t('page_links.to_mentimeter_aria')}
                overflow="visible"
                display="flex"
                flexDirection="row"
              >
                <Box display="flex" mr="space2">
                  <MentimeterSymbol width={28.31} />
                </Box>
                <Box display={['none', 'flex']}>
                  <MentimeterWordmark width={165} />
                </Box>
              </Clickable>
            </Box>

            <>
              <Box
                display={displayOnDesktop}
                as="ul"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <ActionMenu
                  actions={actions}
                  overviewLabel={t('page_links.see_more')}
                />
              </Box>
              <Box
                flex="1"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                {visitor && (
                  <Box display={['none', 'flex']}>
                    <LoginButton mx="space4" />
                  </Box>
                )}
                <HeaderButton />
              </Box>
            </>
            <MobileMenu
              display={displayOnMobile}
              showMenu={showMenu}
              setShowMenu={setShowMenu}
              actions={actions}
              displayOnMobile={displayOnMobile}
            />
          </Wide>
        </Box>
      </Box>

      {showVotingBar && <VotingBar context="Homepage" />}
    </Box>
  );
}

interface MobileMenuProps {
  display: DisplayProperty[];
  showMenu: boolean;
  setShowMenu: (value: boolean) => void;
  actions: MenuAction[];
  displayOnMobile: DisplayProperty[];
}

const MobileMenu = ({
  display,
  showMenu,
  setShowMenu,
  actions,
  displayOnMobile,
}: MobileMenuProps) => {
  const t = useTranslations('common');
  const { user, userFinishedLoading } = useAppState();

  React.useEffect(() => {
    document.body.style.overflow = showMenu ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showMenu]);

  return (
    <DrawerRoot open={showMenu} onOpenChange={setShowMenu}>
      <DrawerTrigger>
        <RagnarClickable
          ml="space4"
          display={displayOnMobile}
          aria-label={t('basic.menu')}
          data-testid="page-header-menu"
          onClick={() => setShowMenu(!showMenu)}
          id="header-menuButton"
        >
          <MenuIcon size={5} color="primary" />
        </RagnarClickable>
      </DrawerTrigger>
      <DrawerContainer side="right">
        <Box
          display={display}
          flex="1 1 auto"
          width="100%"
          height="100%"
          data-testid="page-header-menu-container"
          alignItems="stretch"
          overflow="scroll"
        >
          <Box alignItems="stretch">
            <Box alignItems="flex-end">
              <ScreenReaderOnly>
                <DrawerTitle>Menu items</DrawerTitle>
              </ScreenReaderOnly>
              <DrawerDismiss>
                <IconButton
                  variant="secondary"
                  aria-label="Close menu"
                  onClick={() => setShowMenu(false)}
                  borderRadius="full"
                  size="large"
                >
                  <CrossIcon aria-hidden />
                </IconButton>
              </DrawerDismiss>
            </Box>
            <Box mb="space8" px="space8">
              {actions.map((a) => (
                <ActionCollapsable
                  action={a}
                  overviewLabel={t('page_links.see_more')}
                  openDropdownLabel={`${t('page_links.dropdown')} - ${a.name}`}
                  key={a.name}
                  setShowMenu={setShowMenu}
                />
              ))}
            </Box>
          </Box>
          <Box width={1} alignItems="center" px="space8" pb="space8">
            {userFinishedLoading && user === null && <LoginButton />}
            <HeaderButton mt="space2" size="large" width={1} />
          </Box>
        </Box>
      </DrawerContainer>
    </DrawerRoot>
  );
};

export default Header;
