import { Device } from '@mentimeter/ragnar-device';
import type { BoxT } from '@mentimeter/ragnar-ui/box';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Primary } from '@mentimeter/ragnar-ui/themes';

export const mentiPattern =
  'https://static.mentimeter.com/static/images/DEFAULT.svg';
export const mentiPatternMobile =
  'https://static.mentimeter.com/static/images/DEAFUALT+MOBILE.svg';
const greenPattern =
  'https://images.ctfassets.net/rvt0uslu5yqp/6JQJwC3kLc8q0MVrLTlW7C/d5c1d6fcda82562c8cb6c922a8a17085/Mentimeter_VisualAssets_Web_TrustCenter_2022-09__1___1_.svg';
const patternGreenCTA =
  'https://static.mentimeter.com/static/images/EDUCATION.svg';
const patternGreenCTAMobile =
  'https://static.mentimeter.com/static/images/EDUCATION+MOBILE.svg';
const patternYellowCTA = 'https://static.mentimeter.com/static/images/WORK.svg';
const patternYellowCTAMobile =
  'https://static.mentimeter.com/static/images/WORK+MOBILE.svg';
const patternPurpleCTA =
  'https://static.mentimeter.com/static/images/FEATURES.svg';
const patternPurpleCTAMobile =
  'https://static.mentimeter.com/static/images/FEATURES+MOBILE.svg';
interface Props {
  patternColor: string;
}
export const Pattern = ({ children, patternColor, ...rest }: BoxT & Props) => {
  let desktopPattern;
  let mobilePattern;

  switch (patternColor) {
    case 'patternGreen':
      desktopPattern = greenPattern;
      mobilePattern = greenPattern;
      break;
    case 'patternGreenCTA':
      desktopPattern = patternGreenCTA;
      mobilePattern = patternGreenCTAMobile;
      break;
    case 'patternYellowCTA':
      desktopPattern = patternYellowCTA;
      mobilePattern = patternYellowCTAMobile;
      break;
    case 'patternPurpleCTA':
      desktopPattern = patternPurpleCTA;
      mobilePattern = patternPurpleCTAMobile;
      break;

    default:
      desktopPattern = mentiPattern;
      mobilePattern = mentiPatternMobile;
      break;
  }

  return (
    <>
      <Device.Match greaterThan={1}>
        <Box
          backgroundImage={`url(${desktopPattern})`}
          backgroundPosition="center"
          width="100%"
          {...rest}
        >
          <Primary>{children}</Primary>
        </Box>
      </Device.Match>
      <Device.Match lessThan={2}>
        <Box
          backgroundImage={`url(${mobilePattern})`}
          backgroundPosition="left"
          width="100%"
          {...rest}
        >
          <Primary>{children}</Primary>
        </Box>
      </Device.Match>
    </>
  );
};
